import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Login",
    component: () =>
      import(/* webpackChunkName: "Login" */ "../components/LoginForm.vue"),
  },
  {
    path: "/home-person",
    name: "home-person",
    component: () =>
      import(
        /* webpackChunkName: "home-person" */ "../views/HomePersonView.vue"
      ),
  },
  {
    path: "/home-company",
    name: "home-company",
    component: () =>
      import(
        /* webpackChunkName: "home-company" */ "../views/HomeCompanyView.vue"
      ),
  },
  {
    path: "/user",
    name: "user",
    component: () =>
      import(/* webpackChunkName: "user" */ "../users/components/UserForm.vue"),
  },
  {
    path: "/company",
    name: "company",
    component: () =>
      import(
        /* webpackChunkName: "company" */ "../company/components/CompanyForm.vue"
      ),
  },
  {
    path: "/employee",
    name: "Empleado",
    component: () =>
      import(
        /* webpackChunkName: "employee" */ "../employee/components/EmployeeForm.vue"
      ),
  },
  {
    path: "/concept",
    name: "concept",
    component: () =>
      import(
        /* webpackChunkName: "concept" */ "../concept/components/ConceptForm.vue"
      ),
  },
  {
    path: "/register",
    name: "register",
    component: () =>
      import(
        /* webpackChunkName: "register" */ "../components/RegisterForm.vue"
      ),
  },
  {
    path: "/outlays",
    name: "outlays",
    component: () =>
      import(
        /* webpackChunkName: "outlays" */ "../outlays/components/OutlaysForm.vue"
      ),
  },
  {
    path: "/recharge",
    name: "recharge",
    component: () =>
      import(
        /* webpackChunkName: "outlays-company" */ "../recharge/components/RechargeForm.vue"
      ),
  },
  {
    path: "/validate-outlays",
    name: "validate-outlays",
    component: () =>
      import(
        /* webpackChunkName: "outlays-company" */ "../validate-outlays/components/ValidateOutlaysForm.vue"
      ),
  },
  {
    path: "/reports-company",
    name: "reports-company",
    component: () =>
      import(
        /* webpackChunkName: "outlays-company" */ "../reports/componets/ReportsCompany.vue"
      ),
  },
  {
    path: "/reports-person",
    name: "reports-person",
    component: () =>
      import(
        /* webpackChunkName: "outlays-company" */ "../reports/componets/ReportsPerson.vue"
      ),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
